<template>
    <modal ref="modalOrigenProductos" titulo="Origen de los Productos" tamano="modal-xl" icon="Filledoutline" adicional="Si, estoy de acuerdo" @adicional="redirect()">
        <div class="row justify-content-center">
            <p>Algunos productos pueden proveerse desde varios centros de distribucion </br> Todo esta sujeto a la disponibilidad e inventario de cada centro.</p>
        </div>
        <div class="row mx-0 my-3 justify-content-around">
            <div v-for="(data, idx) in productos" :key="idx" class="card-prod border-top p-2 m-2">
                <img width="90" height="90" :src="data.imagen" alt="" />
                <div class="col f-14 my-auto">
                    <div class="row mx-0 mb-3">
                        <p class="ucfirst f-600">{{ data.nombreProducto }}</p>
                    </div>
                    <div class="row mx-0 mb-1">
                        <p class="f-14">{{ data.presentacion }}</p>
                    </div>
                    <div class="row mx-0 my-2 justify-content-between">
                        <p><b>Pedido: {{ Number(data.cantidad) }} </b> unidades.</p>
                        <p class="text-danger">Cedi de Origen</p>
                        <p v-for="(i,key) in data.origenes" :key="key">
                            <b>{{ i.cedi }}: </b>{{ Number(i.cantidad) }} und.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    props: {
        productos: {
            type: Array,
            default: null
        },
    },
    data(){
        return{
        }
    },
    methods: {
        toggle(){
            this.$refs.modalOrigenProductos.toggle()
        },
        redirect(){
            this.$refs.modalOrigenProductos.toggle()
            this.$router.push({name: 'carrito-compras.checkout'});
        }
    }
}
</script>

<style lang="css" scoped>
.card-prod{
    width: 380px;
    display: flex;
}
.text-aqua{
    color: #03D6BC;
}
</style>
